import React, { useEffect } from "react"
import { Layout } from "@/layout"
import { Box, SEO, Heading, Text, Width, CTA } from "@/components"
import { useLocation } from "@reach/router"
import queryString from "query-string"
import localStorage from "@/utils/localStorage"
import { navigate } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"
import { breakpoints } from "@/theme/index"
import $ from "jquery"

export default function Application({ data }) {
  const location = useLocation()
  const urlParameters =
    typeof window !== "undefined" ? queryString.parseUrl(location.search) : ""
  let referrerId = localStorage.get("referrer_id")
  let refParam = urlParameters.query?.referrer_id
  if (referrerId && !refParam) {
    navigate(`/prep-application?referrer_id=` + referrerId)
  }

  useEffect(() => {
    window.jQuery = $
    if (window.hbspt) {
      window.hbspt.forms.create({
        portalId: "6200944",
        formId: "24cc1eb6-0f55-4be3-97e3-0ea9a29478ac",
        target: "#hubspotForm",
        onFormSubmitted: function () {
          window.location.href = "https://buy.stripe.com/cN22a9aU98Xq8jCdQW"
        },
      })
    }
  }, [])

  return (
    <Layout
      headerType="checkout"
      headerTheme="light"
      footerTheme="light"
      squeeze={true}
    >
      <SEO
        title="Let's start your guitar Journey"
        description="Sonora Beginner Bootcamp is the fastest way to jumpstart your playing and finally understand the guitar."
      />
      <Box
        bg="agate"
        height="100%"
        position="relative"
        zIndex="3"
        top="0"
        width="100%"
        display="flex"
        flexDirection="column"
        textAlign="center"
        pt={["7.5rem", null, null, "9.6rem"]}
        pb={["4.8rem", null, null, "0"]}
      >
        <Width
          display="flex"
          flexDirection={["column", "row"]}
          pt={["1.2rem", null, null, "5.2rem"]}
          pb={["0", null, null, "13.9rem"]}
        >
          <Box
            width={["100%", null, null, 6 / 12]}
            textAlign="left"
            mx="auto"
            pr={["2rem", "2rem", "2rem", "3rem"]}
          >
            <Heading level="xl" mb="1rem" mt={["4rem", "5rem", "5rem", "5rem"]}>
              Let's start your Guitar Journey
            </Heading>
            <Text mb="1rem" mt="3rem" pr={["0rem", "0rem", "2rem", "7rem"]}>
              Sonora Beginner Bootcamp is the <strong>fastest</strong> way to jumpstart your
              playing and finally understand the guitar.
            </Text>
            <Text
              level="2"
              mb={["5rem", "5rem", "5rem", "5rem"]}
              mt="3rem"
              pr={["0rem", "2rem", "2rem", "4rem"]}
            >
              Sonora's 6-week online beginner course is designed to take
              participants with little-to-some guitar experience to
              intensive-ready under the instruction of world-class mentors.
            </Text>
            <HideCard>
              <Box mb="3em">
                <CTA variant="special" to="#survey">
                  {" "}
                  Get Started
                </CTA>
              </Box>
            </HideCard>
            <Box>
              <Box
                width={["100%", null, 12 / 12, null]}
                display="flex"
                flexDirection="row"
                mt={["0rem", null, null, "3rem"]}
              >
                <Box width={[3 / 12, null]} pr="2rem">
                  <Box borderRadius="100%" overflow="hidden">
                    <Img
                      alt={"david"}
                      fluid={data.davidEImage.childImageSharp.fluid}
                    />
                  </Box>
                </Box>
                <Text
                  width={["100%", null, 9 / 12, null]}
                  level="2"
                  mb="1rem"
                  pr={["2rem", "2rem", "2rem", "4rem"]}
                >
                  "This is the most thoughtful, innovative, and intelligent
                  methodology for internalizing not only fretboard fluency, but
                  music in general." <br></br>
                  <strong>- David Engelhardt, Sonora Alumni</strong>
                </Text>
              </Box>
            </Box>
            <Box
              width={["100%", null, 12 / 12, null]}
              display="flex"
              flexDirection="row"
              mt="3rem"
            >
              <Box width={[3 / 12, null]} pr="2rem">
                <Box borderRadius="100%" overflow="hidden">
                  <Img
                    alt={"milton"}
                    fluid={data.miltonPImage.childImageSharp.fluid}
                  />
                </Box>
              </Box>
              <Text
                width={["100%", null, 9 / 12, null]}
                level="2"
                mb="1rem"
                pr={["2rem", "2rem", "2rem", "4rem"]}
              >
                "This guitar learning program is by far the best one ever
                conceptualized." <br></br>
                <strong>- Milton Pizarro, Sonora Alumni</strong>
              </Text>
            </Box>
            <Box
              width={["100%", null, 12 / 12, null]}
              display="flex"
              flexDirection="row"
              mt="3rem"
            >
              <Box width={[3 / 12, null]} pr="2rem">
                <Box borderRadius="100%" overflow="hidden">
                  <Img
                    alt={"david"}
                    fluid={data.erikLImage.childImageSharp.fluid}
                  />
                </Box>
              </Box>
              <Text
                width={["100%", null, 9 / 12, null]}
                level="2"
                mb="1rem"
                pr={["2rem", "2rem", "2rem", "4rem"]}
              >
                "I went from someone who can't improvise at all to being able to
                freely play and improvise over any chord changes in 3 months."{" "}
                <br></br>
                <strong>- Erik Luo, Sonora Alumni</strong>
              </Text>
            </Box>
            <Box
              width={["100%", null, 12 / 12, null]}
              display="flex"
              flexDirection="row"
              mt="3rem"
              mb="3rem"
            >
              <Box width={[3 / 12, null]} pr="2rem">
                <Box borderRadius="100%" overflow="hidden">
                  <Img
                    alt={"kevin"}
                    fluid={data.katieBImage.childImageSharp.fluid}
                  />
                </Box>
              </Box>
              <Text
                width={["100%", null, 9 / 12, null]}
                level="2"
                mb="1rem"
                pr={["2rem", "2rem", "2rem", "4rem"]}
              >
                "It was an incredibly worthwhile investment that helped me break
                out of a years long rut as a writer and player." <br></br>
                <strong>- Katie Boeck, Sonora Alumni</strong>
              </Text>
            </Box>
          </Box>

          <Box width={["90%", null, null, 6 / 12]} id="survey" mx="auto">
            <ApplicationCard>
              <Heading level="2" mb={["5rem", null, null, "2rem"]}>
                Tell us about yourself
              </Heading>
              <div id="hubspotForm"></div>
            </ApplicationCard>
          </Box>
        </Width>
      </Box>
    </Layout>
  )
}

const ApplicationCard = styled.div`
  background-color: white;
  border-radius: 0.5em;
  padding: 1em;
`
const HideCard = styled.div`
  @media (min-width: ${breakpoints[1]}) {
    display: none;
  }
`

/* Image and Other GQL Queries */
export const applicationQuery = graphql`
  query {
    davidEImage: file(relativePath: { eq: "davide.png" }) {
      childImageSharp {
        fluid(maxWidth: 824, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    kevinSImage: file(relativePath: { eq: "kevins.png" }) {
      childImageSharp {
        fluid(maxWidth: 824, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    miltonPImage: file(relativePath: { eq: "miltonp.png" }) {
      childImageSharp {
        fluid(maxWidth: 824, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    katieBImage: file(relativePath: { eq: "katieb.png" }) {
      childImageSharp {
        fluid(maxWidth: 824, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    erikLImage: file(relativePath: { eq: "erikl.png" }) {
      childImageSharp {
        fluid(maxWidth: 824, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
  }
`
